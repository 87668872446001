import * as React from "react"
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import media from "../styles/media"

const RectCon = styled.div`
  width: 100vw;
  height: 80vh;
  .gatsby-image-wrapper {
    width: 100vw;
    height: 80vh;
  }
  img {
    width: 100vw;
    height: 80vh;
  }

  ${media.laptop`
    width: 100vw;
    height: 100vh;
    .gatsby-image-wrapper {
      width: 100vw;
      height: 100vh;
    }
    img {
      width: 100vw;
      height: 100vh;
    }
  `}
`

const Rect = () => {

  return (
    <RectCon>
      <StaticImage src='../images/divider.jpg' alt="rect" />
    </RectCon>
  )
}

export default Rect