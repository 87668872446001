import React, { useContext } from "react"
import styled from 'styled-components'
// import { StaticImage } from "gatsby-plugin-image"
import LanguageContext from '../../context/languageContext'

import media from '../../styles/media'
import LogoImg from '../../images/logo.svg'
import { isMobile } from "react-device-detect"

const LogoDiv = styled.div`
  position: absolute;
  top: 0;
  .top {
    display: block;
    justify-content: center;
    align-items: center;
    width: 100vw;
    text-align: -webkit-center;
    padding: 0 var(--gutter-s);
    position: absolute;
    top: 0;

    .logo{
      position: relative;
      width: 176px;
      height: 108px;
      background: black;
      mix-blend-mode: normal;
      display: flex;
      padding: 0 var(--gutter-s);
      img {
        object-fit: contain !important;
        width: 80%;
        height: auto;
        margin: auto 10%;
      }
    }

    .language {
      display: flex;
      margin-top: 30px;
      text-transform: uppercase;
      font-size: 8px;
      color: white;
      line-height: 16px;
      button{
        display:none;
        font-size: 8px;
        line-height: 16px;
      }
    }
  }

  ${media.laptop`
    padding: 0 var(--gutter-m);
    width: calc(100vw - calc(var(--gutter-m) * 2));

    .top {
      justify-content: space-between;
      width: calc(100vw - calc(var(--gutter-m) * 2));
      display: flex;
      text-align: none;
      .logo{
        width: 325px;
        height: 144px;
        margin-left: -50px;
        background: black;
        mix-blend-mode: normal;
        display: flex;
        padding: 0;
        img {
          object-fit: contain !important;
          width: 225px;
          height: 50px;
          margin: auto 50px;
        }
      }
      .language {
        display: block;
        font-size: 14px;
          display: flex;
        button {
          cursor: pointer;
          background: transparent;
          border: none;
          outline: none;
          color: white;
          text-transform: uppercase;
          font-size: 14px;
          width: fit-content;
          display: flex;
          width: 55px;
          align-items: center;
          img {
            width: 20px;
            height: 14px;
            margin-left: 5px;
            margin-bottom: 0;
            object-fit: cover;
          }
          &::after {
            content: '';
            display: block;
            width: 0;
            height: 1px;
            background: white;
            transition: width .3s linear;
            position: absolute;
            top: 95px;
          }
          &:hover {
            &::after {
              width: 20px;
              position: absolute;
              top: 95px;
            }
          }

          &.active {
            &::after {
              width: 20px;
              position: absolute;
              top: 95px;
            }
          }
        }
      }
    }
  `}
`

const Logo = () => {
  const language = useContext(LanguageContext);

  const setEng = () =>{
    language.updateLang('english')
  }

  const setMan = () =>{
    language.updateLang('mandarin')
  }

  const setFrench = () =>{
    language.updateLang('french')
  }

  return (
    <LogoDiv>
      <div className="top">
        <div className="logo">
          <img src={LogoImg} alt='logo' />
        </div>
        {!isMobile && (
        <div className="language">
          <button onClick={setEng} className={language.language === 'english' ? 'active' : ''}>En <img src="https://camo.githubusercontent.com/0f213bb0d662e12cc28d9da053251a1c76d688d69100a70c2a4d69bbf842e625/68747470733a2f2f75706c6f61642e77696b696d656469612e6f72672f77696b6970656469612f636f6d6d6f6e732f612f61652f466c61675f6f665f7468655f556e697465645f4b696e67646f6d2e737667" alt="flag" /></button> &nbsp;&nbsp;
          <button onClick={setMan} className={language.language === 'mandarin' ? 'active' : ''}>Ch <img src="https://camo.githubusercontent.com/36b999b8d62e3dad262dec7ba75ef4cad465cec20fc3c75eaab329a6a63bf4ee/68747470733a2f2f75706c6f61642e77696b696d656469612e6f72672f77696b6970656469612f636f6d6d6f6e732f662f66612f466c61675f6f665f7468655f50656f706c65253237735f52657075626c69635f6f665f4368696e612e737667" alt="flag" /></button> &nbsp;&nbsp;
          <button onClick={setFrench} className={language.language === 'french' ? 'active' : ''}>Fr <img src="https://camo.githubusercontent.com/f4a3d88a2f40abc7a7ff08a39ae6ca21366250a5d603c131f6ad577239d0c119/68747470733a2f2f75706c6f61642e77696b696d656469612e6f72672f77696b6970656469612f636f6d6d6f6e732f632f63332f466c61675f6f665f4672616e63652e737667" alt="flag" /></button>
        </div>
        )}
      </div>
    </LogoDiv>
  );
}
export default Logo;