import * as React from "react"

import TopContact from "./top"
import BottomContact from "./bottom"

const ContactSection = () => (
  <div  >
    <TopContact />
    <BottomContact />
  </div>
)

export default ContactSection
