import React, { useContext, useEffect, useState } from "react"
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import { useInView } from 'react-intersection-observer'
import { Reveal, Tween } from "react-gsap"
import LanguageContext from '../context/languageContext'

import media from '../styles/media'

import Warehouse1 from '../images/warehouse1.jpg'
import Warehouse2 from '../images/warehouse2.jpg'
import Warehouse3 from '../images/warehouse3.jpg'
import Warehouse4 from '../images/warehouse4.jpg'

const WarehousingDiv = styled.div`
  color: white;
  background: black;
  padding: var(--gutter-l) var(--gutter-s);
  width: 100%;

  .heading {
    margin-bottom: var(--gutter-s);
  }

  .images {
    display: grid;
    grid-template-columns: 40% 20% 40%;
    grid-template-rows: 1fr 1fr;
    gap: 15px;
    margin-top: var(--gutter-m);
    &.desktop {
      display: none;
    }
    &.mobile {
      display: grid;
      overflow: hidden;


      .overlay {
        background: black;
        width: 100%;
        height: 202px;
        margin-top: -1px;
        z-index: 3;
        grid-column: 1;
        grid-row: 1;
      }

      .image {
      align-self: self-end;
    }

    .imagestart {
      align-self: self-start;
    }

    img {
      object-fit: contain !important;
      width: -webkit-fill-available;
      margin: 0;
      grid-column: 1;
      grid-row: 1;
    }

    .one {
      grid-column: 1/2;
      grid-row: 1;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      height: 200px;
    }
    .two {
      align-self: flex-end;
      grid-column: 2/4;
      grid-row: 1;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      height: 200px;
    }
    .three {
      grid-column: 1/3;
      grid-row: 2;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      height: 200px;
      img{
        align-self: start;
      }
    }
    .four {
      grid-column: 3/4;
      grid-row: 2;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      height: 200px;
      img{
        align-self: start;
      }
    }
    }

    
  }

  .body {
    width: 90%;
  }

  ${media.tablet`
    grid-column-gap: var(--gutter-m);
    padding: var(--gutter-ml) var(--gutter-l) var(--gutter-ml) var(--gutter-left);
    width: 100%;
    max-width: 100vw;
    overflow: hidden;
    align-self: center;

    .text {
      display: flex;
      flex-direction: column;
      align-self: center;
    }

    .heading {
      margin-bottom: 0;
      height: calc(10vh + 40px);
      height: auto;
    }

    .first {
      margin-top: 40px;
    }

    .images {
      max-width: 45vw;
      overflow: hidden;
      margin: 50px auto 0;
      &.mobile {
        display: none;
      }
      &.desktop {
        display: grid;
      }


      .one {
        grid-column: 1/2;
        grid-row: 1;
      }
      .two {
        grid-column: 2/4;
        grid-row: 1;
      align-self: flex-end;
        img{
          object-fit: contain;
        }
      }
      .three {
        grid-column: 1/3;
        grid-row: 2;
      }
      .four {
        grid-column: 3/4;
        grid-row: 2;
      }


      .image1 {
        z-index: 2;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        .img {
          height: 300px;
          z-index: 2;
          grid-column: 1;
          grid-row: 1;
          width: 100%;
          object-fit: cover;
        }
        .overlay {
          position: absolute;
          background: black;
          width: 340px;
          height: 450px;
          margin-top: -1px;
          grid-column: 1;
          grid-row: 1;
          z-index: 3;
        }
      }

    .image2 {
      z-index: 2;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      .img {
        height: 300px;
        z-index: 2;
        grid-column: 1;
        grid-row: 1;
        width: 100%;
        object-fit: cover;
      }
      .overlay {
        position: absolute;
        background: black;
        width: 25vw;
        height: 450px;
        margin-top: -1px;
        grid-column: 1;
        grid-row: 1;
        z-index: 3;
      }
    }

    .image3 {
      z-index: 2;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      .img {
        height: 300px;
        z-index: 2;
        grid-column: 1;
        grid-row: 1;
        width: 100%;
        object-fit: cover;
      }
      .overlay {
        position: absolute;
        background: black;
        width: 500px;
        height: 450px;
        margin-top: -1px;
        grid-column: 1;
        grid-row: 1;
        z-index: 3;
      }
    }

    .image4 {
      z-index: 2;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      .img {
        height: 300px;
        z-index: 2;
        grid-column: 1;
        grid-row: 1;
        width: 100%;
        object-fit: cover;
      }
      .overlay {
        position: absolute;
        background: black;
        width: 20vw;
        height: 450px;
        margin-top: -1px;
        grid-column: 1;
        grid-row: 1;
        z-index: 3;
      }
    }
    }

    .three, .four {
      place-self: flex-start;
    }
  `}

  ${media.laptop`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: var(--gutter-m);
    padding: var(--gutter-ml) var(--gutter-l) var(--gutter-ml) var(--gutter-left);
    width: 100%;
    max-width: 100vw;
    overflow: hidden;
    align-self: center;

    .text {
      display: flex;
      flex-direction: column;
      align-self: center;
    }

    .heading {
      margin-bottom: 0;
      height: auto;
    }

    .images {
      margin: 0;
      margin-top: 0;
      max-width: 45vw;
      overflow: hidden;
      &.mobile {
        display: none;
      }
      &.desktop {
        display: grid;
      }

      .image1 {
        z-index: 2;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        .img {
          height: 300px;
          z-index: 2;
          grid-column: 1;
          grid-row: 1;
          width: 100%;
          object-fit: cover;
        }
        .overlay {
          position: absolute;
          background: black;
          width: 340px;
          height: 450px;
          margin-top: -1px;
          grid-column: 1;
          grid-row: 1;
          z-index: 3;
        }
      }

    .image2 {
      z-index: 2;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      .img {
        height: 300px;
        z-index: 2;
        grid-column: 1;
        grid-row: 1;
        width: 100%;
        object-fit: cover;
      }
      .overlay {
        position: absolute;
        background: black;
        width: 25vw;
        height: 450px;
        margin-top: -1px;
        grid-column: 1;
        grid-row: 1;
        z-index: 3;
      }
    }

    .image3 {
      z-index: 2;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      .img {
        height: 300px;
        z-index: 2;
        grid-column: 1;
        grid-row: 1;
        width: 100%;
        object-fit: cover;
      }
      .overlay {
        position: absolute;
        background: black;
        width: 500px;
        height: 450px;
        margin-top: -1px;
        grid-column: 1;
        grid-row: 1;
        z-index: 3;
      }
    }

    .image4 {
      z-index: 2;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      .img {
        height: 300px;
        z-index: 2;
        grid-column: 1;
        grid-row: 1;
        width: 100%;
        object-fit: cover;
      }
      .overlay {
        position: absolute;
        background: black;
        width: 20vw;
        height: 450px;
        margin-top: -1px;
        grid-column: 1;
        grid-row: 1;
        z-index: 3;
      }
    }
    }

    .three, .four {
      place-self: flex-start;
    }
  `}
`

const Warehousing = () => {
  const language = useContext(LanguageContext);
  const [shown, setShown] = useState(false)

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if(inView) {
      setShown(true)
    }
  }, [inView])
  
  return (
    <WarehousingDiv
      data-background-color='rgb(0, 0, 0)'
      className='js-color-stop'
    >
      <div className="text">
        <div className="heading" ref={ref}>
          <div className={shown ? 'head shown' : 'head hidden'}>
              {language.language === 'english' && (
                <p>
                  Warehousing
                </p>
              )}
              {language.language === 'mandarin' && (
                <p>
                  仓储与经销
                </p>
              )}
              {language.language === 'french' && (
                <p>
                  Entreposage
                </p>
              )}
          </div>
          <div  className={shown ? 'head bottom shown' : 'head bottom hidden'}>
            {language.language === 'english' && (
              <span>
                &amp; Distribution
              </span>
            )}
            {language.language === 'mandarin' && (
              <>
              </>
            )}
            {language.language === 'french' && (
              <span>
                et Distribution
              </span>
            )}
          </div>
        </div>
        <Reveal>
          <Tween from={{ paddingTop: `10px`, opacity: 0 }} duration={1} delay={1.5}>
            <p className="body first">
              {language.language === 'english' && (
                <>
                  Dry bulk products are stored in first class warehousing facilities which are equipped to deal specifically with weather sensitive materials. Highly specialized petro-chemicals are stored in our bulk liquid storage facilities which are located within close proximity to the Refineries and Port as well as inland to service our customers in various locations.
                </>
              )}
              {language.language === 'mandarin' && (
                <>
                  干燥散装货品被存放于一流的仓库设施内，并自带用于气候敏感货品工具。高度专业化的石化产品被存于液体散装储藏设施之内，位于精炼厂及港口临近的地理范围，并在内陆也有此等设施，以便为不同地区的客户提供更好的服务。
                </>
              )}
              {language.language === 'french' && (
                <>
                  Les produits secs en vrac sont stockés dans des entrepôts de première classe, équipés pour traiter spécifiquement les matériaux sensibles aux intempéries.
                </>
              )}
            </p>
            <p className="body">
              {language.language === 'english' && (
                <>
                  Petronex have agreements in place with reputable hauliers in the various industries we are active in and are able to meet all of our clients specific transport requirements.
                </>
              )}
              {language.language === 'mandarin' && (
                <>
                  Petronex与信誉良好的各行业搬运公司签订协议，我们积极参与并能够满足我们所有客户的特定运输要求。
                </>
              )}
              {language.language === 'french' && (
                <>
                  Des produits pétrochimiques hautement spécialisés sont stockés dans nos installations de stockage de liquides en vrac, situées à proximité des raffineries et du port, ainsi qu'à l'intérieur des terres, afin de desservir nos clients en divers endroits.
                </>
              )}
            </p>
            <p className="body">
              {language.language === 'english' && (
                <>
                  Petronex actively invests in assets and infrastructure to facilitate the supply of various commodities that we trade.
                </>
              )}
              {language.language === 'mandarin' && (
                <>
                  Petronex积极投资于资产和基础设施，以促进我们供应各种商品的贸易。
                </>
              )}
              {language.language === 'french' && (
                <>
                  Petronex a conclu des accords avec des transporteurs réputés dans les différents secteurs où nous sommes actifs et est en mesure de répondre à toutes les exigences spécifiques de nos clients en matière de transport.
                  Petronex investit activement dans des actifs et des infrastructures afin de faciliter l'approvisionnement des différentes matières premières que nous commercialisons
                </>
              )}
            </p>
          </Tween>
        </Reveal>
      </div>
      <Reveal>
        <div className="images desktop">
          <div className="image1 one">
            <StaticImage src="../images/warehouse1.jpg" alt="divider" className="image" />
            <Tween to={{ width: '0px' }} duration={1.2} delay={.5}>
              <div className="overlay"></div>
            </Tween>
          </div>
          <div className="image2 two">
            <StaticImage src="../images/warehouse2.jpg" alt="divider" className="image" />
            <Tween to={{ width: '0px' }} duration={1.2} delay={.8}>
              <div className="overlay"></div>
            </Tween>
          </div>
          <div className="image3 three">
            <StaticImage src="../images/warehouse3.jpg" alt="divider" className="image" />
            <Tween to={{ width: '0px' }} duration={1.2} delay={1.2}>
              <div className="overlay"></div>
            </Tween>
          </div>
          <div className="image4 four">
            <StaticImage src="../images/warehouse4.jpg" alt="divider" className="image" />
            <Tween to={{ width: '0px' }} duration={1.2} delay={1.6}>
              <div className="overlay"></div>
            </Tween>
          </div>
        </div>
      </Reveal>
      <Reveal>
      <Reveal>
        <div className="images mobile">
          <div className="image1 one">
            <img src={Warehouse1} alt="divider" className="image" />
            <Tween to={{ width: '0px' }} duration={1.2} delay={.5}>
              <div className="overlay"></div>
            </Tween>
          </div>
          <div className="image2 two">
            <img src={Warehouse2} alt="divider" className="image" />
            <Tween to={{ width: '0px' }} duration={1.2} delay={.8}>
              <div className="overlay"></div>
            </Tween>
          </div>
          <div className="image3 three">
            <img src={Warehouse3} alt="divider" className="image" />
            <Tween to={{ width: '0px' }} duration={1.2} delay={1.2}>
              <div className="overlay"></div>
            </Tween>
          </div>
          <div className="image4 four">
            <img src={Warehouse4} alt="divider" className="image" />
            <Tween to={{ width: '0px' }} duration={1.2} delay={1.6}>
              <div className="overlay"></div>
            </Tween>
          </div>
        </div>
      </Reveal>
        {/* <div className="images mobile">
          <div className="image1 one">
            <img src={Warehouse1} alt="divider" className="image" />
          </div>
          <div className="image2 two">
            <img src={Warehouse2} alt="divider" className="image" />
          </div>
          <div className="image3 three">
            <img src={Warehouse3} alt="divider" className="image" />
          </div>
          <div className="image4 four">
            <img src={Warehouse4} alt="divider" className="image" />
          </div>
        </div> */}
      </Reveal>

    </WarehousingDiv>
  );
}
export default Warehousing;