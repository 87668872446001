import React, { useEffect, useState, useContext } from "react"
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import { useInView } from 'react-intersection-observer'
import { Reveal, Tween } from "react-gsap"
import LanguageContext from '../context/languageContext'

import media from '../styles/media'

const HistoryDiv = styled.div`
  padding: var(--gutter-l) var(--gutter-s);
  width: 100%;

  .heading {
    margin-bottom: var(--gutter-l);
  }

  .body {
    margin-bottom: var(--gutter-m);
  }

  .first {
    z-index: 1;
    margin-bottom: var(--gutter-m);
    p {
      padding-left: 20px;
    }

    .imageGrid {
      display: grid;
      grid-template-columns: .25fr 1fr .25fr;
      grid-template-rows: .75fr .25fr 1.25fr;
      margin-bottom: var(--gutter-m);

      .one {
        grid-column: 1/4;
        grid-row: 1/3;
      }
      .two {
        grid-column: 2/3;
        grid-row: 2/4;
      }
    }

    .desk {
      display: none;
    }
  }

  .second{
    z-index: 1;
    p {
      margin: 0 0 var(--gutter-m);
    }

    .mob {
      margin: 0 40px 0;
      margin-bottom: var(--gutter-s);
    }

    .imageGrid {
      display: none;
    }
  }

  ${media.tablet`
    padding: var(--gutter-m);
    width: 100%;
    max-width: 100vw;
  `}
  
  ${media.laptop`
    display: grid;
    grid-template-columns: 55% 45%;
    grid-template-rows: .5fr 5fr;
    padding: var(--gutter-ml) var(--gutter-m) var(--gutter-ml) var(--gutter-left);
    width: 100vw;

    .heading {
      grid-column: 1;
      margin-bottom: 0;
    }

    .body {
      margin-bottom: var(--gutter-m);
      padding-top: 10px;
    }

    .first {
      grid-column: 1;
      padding: var(--gutter-s) var(--gutter-m);
      p {
        padding-left: 20px;
      }

      .year {
        animation-delay: 1s;
        span {
          animation-delay: 1s;
        }
      }

      .imageGrid {
        display: none;
      }

      .desk {
        display: block;
        img {
        /* height: 80%; */
        object-fit: contain !important;
        }
      }

      .img1{
        z-index: 2;
        margin-top: 100px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        img {
          height: 400px;
          max-width: 250px;
          width: 250px;
          z-index: 2;
          grid-column: 1;
          grid-row: 1;
          object-fit: cover;
        }
        .overlay {
          position: absolute;
          background: white;
          height: 401px;
          width: 350px;
          max-width: 350px;
          margin: 0 auto;
          margin-left: -1px;
          margin-top: -1px;
          grid-column: 1;
          grid-row: 1;
          z-index: 3;
        }
      }
    }

    .second{
      grid-column: 2;
      grid-row: 1/3;

      .mob {
        display: none;
      }

      p {
        margin: 0 var(--gutter-m) var(--gutter-m);
      }


      .imageGrid {
        display: grid;
        grid-template-columns: .25fr 1fr .25fr;
        grid-template-rows: .75fr .25fr 1.25fr;
        margin-bottom: var(--gutter-m);
        justify-items:center;

        .one {
          grid-column: 1/4;
          grid-row: 1/3;
        }
        .two {
          grid-column: 2/3;
          grid-row: 2/4;
        }
      }

      .image2 {
        z-index: 2;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        img {
          height: 230px;
          max-width: 450px;
          width: 450px;
          z-index: 2;
          grid-column: 1;
          grid-row: 1;
          margin: 0 auto;
          object-fit: cover;
        }
        .overlay {
          position: absolute;
          background: white;
          height: 251px;
          margin: 0 auto;
          width: 500px;
          max-width: 500px;
          margin-top: -1px;
          grid-column: 1;
          grid-row: 1;
          z-index: 3;
          margin-left: -1px;
        }
      }
      .image3 {
        z-index: 2;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
          width: 0px;
        
        img {
          height: 400px;
          max-width: 250px;
          z-index: 2;
          grid-column: 1;
          grid-row: 1;
          width: 250px;
          object-fit: cover;
          object-position: left;
        }
        .overlay {
          position: absolute;
          background: white;
          height: 401px;
          width: 300px;
          max-width: 300px;
          margin: 0 auto;
          margin-top: -1px;
          grid-column: 1;
          grid-row: 1;
          z-index: 3;
          margin-left: -1px;
        }
      }
      .image4 {
        z-index: 2;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        img {
          height: 250px;
          max-width: 450px;
          z-index: 2;
          grid-column: 1;
          grid-row: 1;
          width: 450px;
          margin: 0 auto;
          object-fit: cover;
        }
        .overlay {
          position: absolute;
          background: white;
          height: 253px;
          width: 520px;
          max-width: 520px;
          margin: 0 auto;
          margin-top: -1px;
          grid-column: 1;
          grid-row: 1;
          z-index: 3;
          margin-left: -1px;
        }
      }
    }
  `}

  ${media.laptopL`
    display: grid;
    grid-template-columns: 55% 45%;
    grid-template-rows: .5fr 5fr;
    padding: var(--gutter-ml) var(--gutter-m) var(--gutter-ml) var(--gutter-left);
    width: 100%;

    .heading {
      grid-column: 1;
      margin-bottom: 0;
    }

    .body {
      margin-bottom: var(--gutter-m);
      padding-top: 10px;
    }

    .first {
      grid-column: 1;
      padding: var(--gutter-s) var(--gutter-m);
      p {
        padding-left: 20px;
      }

      .year {
        animation-delay: 1.5s;
        span {
          animation-delay: 1.5s;
        }
      }

      .imageGrid {
        display: none;
      }

      .desk {
        display: block;
        img {
        /* height: 80%; */
        object-fit: contain !important;
        }
      }

      .img1{
        z-index: 2;
        margin-top: 100px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        img {
          height: 450px;
          max-width: 300px;
          width: 300px;
          z-index: 2;
          grid-column: 1;
          grid-row: 1;
          object-fit: cover;
        }
        .overlay {
          position: absolute;
          background: white;
          height: 451px;
          width: 400px;
          max-width: 400px;
          margin: 0 auto;
          margin-left: -1px;
          margin-top: -1px;
          grid-column: 1;
          grid-row: 1;
          z-index: 3;
        }
      }
    }

    .second{
      grid-column: 2;
      grid-row: 1/3;

      .mob {
        display: none;
      }

      p {
        margin: 0 var(--gutter-m) var(--gutter-m);
      }


      .imageGrid {
        display: grid;
        grid-template-columns: .25fr 1fr .25fr;
        grid-template-rows: .75fr .25fr 1.25fr;
        margin-bottom: var(--gutter-m);
        justify-items:center;

        .one {
          grid-column: 1/4;
          grid-row: 1/3;
        }
        .two {
          grid-column: 2/3;
          grid-row: 2/4;
        }
      }

      .image2 {
        z-index: 2;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        img {
          height: 280px;
          max-width: 500px;
          width: 500px;
          z-index: 2;
          grid-column: 1;
          grid-row: 1;
          margin: 0 auto;
          object-fit: cover;
        }
        .overlay {
          position: absolute;
          background: white;
          height: 301px;
          margin: 0 auto;
          width: 550px;
          max-width: 550px;
          margin-top: -1px;
          grid-column: 1;
          grid-row: 1;
          z-index: 3;
          margin-left: -1px;
        }
      }
      .image3 {
        z-index: 2;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
          width: 0px;
        
        img {
          height: 450px;
          max-width: 300px;
          z-index: 2;
          grid-column: 1;
          grid-row: 1;
          width: 300px;
          object-fit: cover;
          object-position: left;
        }
        .overlay {
          position: absolute;
          background: white;
          height: 451px;
          width: 350px;
          max-width: 350px;
          margin: 0 auto;
          margin-top: -1px;
          grid-column: 1;
          grid-row: 1;
          z-index: 3;
          margin-left: -1px;
        }
      }
      .image4 {
        z-index: 2;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        img {
          height: 300px;
          max-width: 500px;
          z-index: 2;
          grid-column: 1;
          grid-row: 1;
          width: 500px;
          margin: 0 auto;
          object-fit: cover;
        }
        .overlay {
          position: absolute;
          background: white;
          height: 303px;
          width: 570px;
          max-width: 570px;
          margin: 0 auto;
          margin-top: -1px;
          grid-column: 1;
          grid-row: 1;
          z-index: 3;
          margin-left: -1px;
        }
      }
    }
  `}
`

const History = () => {
  const [shown, setShown] = useState(false)
  const language = useContext(LanguageContext);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if(inView) {
      setShown(true)
    }
  }, [inView])

  return (
    <HistoryDiv
      data-background-color='rgb(255, 255, 255)'
      className='js-color-stop'
    >
      <div className="heading" ref={ref}>
        <p  className={shown ? 'head shown' : 'head hidden'}>
            {language.language === 'english' && (
              <span>
                History
              </span>
            )}
            {language.language === '' && (
              <span>
                History
              </span>
            )}
            {language.language === 'mandarin' && (
              <p>
                公司历史
              </p>
            )}
            {language.language === 'french' && (
              <span>
                Context
              </span>
            )}
        </p>
      </div>
      <div className="first">
        <p  className={shown ? 'head year shown' : 'head year hidden'}>
          <span>1984.</span>
        </p>
      <Reveal>
          <Tween from={{ paddingTop: `10px`, opacity: 0 }} duration={1} delay={1.5}>
            <p className='body'>
                {language.language === 'english' && (
                  <>
                    Petronex is a commodity trading company that was established in 1984 in Johannesburg, South Africa. Initially, the company focused on Crude Oil and Oil Products, trading into the Southern African region. Our clients were principally the major oil companies, refineries and government procurement companies.
                  </>
                )}
                {language.language === '' && (
                  <>
                    Petronex is a commodity trading company that was established in 1984 in Johannesburg, South Africa. Initially, the company focused on Crude Oil and Oil Products, trading into the Southern African region. Our clients were principally the major oil companies, refineries and government procurement companies.
                  </>
                )}
                {language.language === 'mandarin' && (
                  <>
                    Petronex是一家1984年于南非约翰内斯堡的货物贸易公司。初期公司专注于原油和石油产品，供应南部非洲地区。我们的客户曾为主要的石油公司，精炼产以及政府采购公司。
                  </>
                )}
                {language.language === 'french' && (
                  <>
                    Petronex est une société de commerce de produits de base qui a été créée en 1984 à Johannesburg, en Afrique du Sud. Au départ, l'entreprise était axée sur le pétrole brut et les produits pétroliers, dans la région de l'Afrique australe. Nos clients étaient principalement les grandes compagnies pétrolières, les raffineries et les sociétés d'approvisionnement du gouvernement.
                  </>
                )}
            </p>
        </Tween>
        </Reveal>
        <div className="imageGrid">
          <StaticImage src="../images/history1.jpg" alt="divider" className="one" />
          <StaticImage src="../images/history2.jpg" alt="divider" className="two" />
        </div>
        <div className="img1">
          <StaticImage src="../images/history3.jpg" alt="divider" className="desk" />
          <Reveal>
            <Tween to={{ width: '0%' }} duration={1.5}>
              <div className="overlay"></div>
            </Tween>
          </Reveal>
        </div>
      </div>
      <div className="second">
        <div className="imageGrid">
        <div className="image2 one">
          <StaticImage src="../images/history1.jpg" alt="divider" className="one" />
          <Reveal>
            <Tween to={{ width: '0px' }} duration={1.5} delay={.5}>
              <div className="overlay"></div>
            </Tween>
          </Reveal>
        </div>
        <Reveal>
          <Tween to={{ width: '300px' }} duration={1.5} delay={1.5}>
            <div className="image3 two">
              <StaticImage src="../images/history2.jpg" alt="divider" className="two" />
            </div>
          </Tween>
        </Reveal>
        </div>
      <Reveal>
          <Tween from={{ paddingTop: `10px`, opacity: 0 }} duration={1} delay={.5}>
            <p className='body'>
                {language.language === 'english' && (
                  <>
                    In 2003, Petronex identified opportunities to expand its product range to include industrial minerals and raw materials. Since its inception, Petronex has been a reputable supplier to the industry and today, trades throughout the Southern and Central African region.
                  </>
                )}
                {language.language === '' && (
                  <>
                    In 2003, Petronex identified opportunities to expand its product range to include industrial minerals and raw materials. Since its inception, Petronex has been a reputable supplier to the industry and today, trades throughout the Southern and Central African region.
                  </>
                )}
                {language.language === 'mandarin' && (
                  <>
                    2003年，Petronex认准了扩展其货品种类的机会，融入了工业矿产及原料。自建立至今，Petronex一直是业内信誉良好的供应商，并且贸易活动贯彻于非洲中南部。
                  </>
                )}
                {language.language === 'french' && (
                  <>
                    En 2003, Petronex a identifié les opportunités d'élargir sa gamme de produits pour y inclure les minéraux industriels et les matières premières. Depuis sa création, Petronex est un fournisseur réputé de l'industrie et, aujourd'hui, fait du commerce dans toute la région de l'Afrique australe et centrale.
                  </>
                )}
            </p>
        </Tween>
        </Reveal>
        <StaticImage src="../images/history3.jpg" alt="divider" className="mob" />
        <div className="image4">
          <StaticImage src="../images/history4.jpg" alt="divider" className="desk" />
          <Reveal>
            <Tween to={{ width: '0px' }} duration={1.5}>
              <div className="overlay"></div>
            </Tween>
          </Reveal>
        </div>
      </div>
    </HistoryDiv>
  );
}

export default History;