import React, { useContext } from "react"
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import LanguageContext from '../context/languageContext'
import LoadContext from '../context/loadContext'

import media from '../styles/media'
import Logo from "./Logo"

const HeroDiv = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  position: relative;

  main {
    height: 100vh;
    width: 100vw;

    .frame {
      height: 100vh;
      width: 100vw;
      
      #gl {
        height: 100vh;
        width: 100vw;
      }
    }
  }

  .hero {
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    top: 0;
    left: 0;
  }
  
  .bg {
    position: absolute;
    top: 0;
    left: 0;
  }

  .title {
    position: absolute;
    top: 45%;
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0;
    font-size: 8.5vw;
    width: 100%;
    p {
      /* padding: 0 0 0 80px; */
      padding: 0 10px;
      margin: 0;
      white-space: nowrap;
    }
    span {
      /* padding: 0 80px 0 0; */ 
      &.chinese {
        padding: 0;
      }
    }

    .top {
      height: 50px;
      overflow: hidden;
      span {
        transform: translate(0,105%);
        display: block;
        animation: reveal 1.5s forwards .5s;
      }
    }
    .bottom {
      height: 50px;
      overflow: hidden;
      p {
        transform: translate(0,105%);
        display: block;
        animation: reveal 1.5s forwards 1.5s;
      }
    }
  }

  .scroll {
    position: absolute;
    text-transform: uppercase;
    bottom: 4vh;
    right: var(--gutter-s);
    color: white;
  }
  

  @keyframes reveal {
    to {
      transform: translate(0,0);
    }
  }
  
  ${media.tablet`
    height: 100vh;
    min-height: 700px;

    .hero {
      height: 100vh;
      min-height: 700px;
    }

    .title {
    position: absolute;
    top: 45%;
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0;
    font-size: 8.5vw;
    width: 100%;
    p {
      /* padding: 0 0 0 80px; */
      padding: 0 10px;
      margin: 0;
      white-space: nowrap;
    }
    span {
      /* padding: 0 80px 0 0; */ 
      &.chinese {
        padding: 0;
      }
    }

    .top {
      height: 90px;
      overflow: hidden;
      span {
        transform: translate(0,105%);
        display: block;
        animation: reveal 1.5s forwards .5s;
      }
    }
    .bottom {
      height: 90px;
      overflow: hidden;
      p {
        transform: translate(0,105%);
        display: block;
        animation: reveal 1.5s forwards 1.5s;
      }
    }
  }

    .bg {
      position: absolute;
    }
  `}

  ${media.laptop`
  .title {
      position: absolute;
      font-size: 90px;
      width: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      top: 35%;
      letter-spacing: -1px;
      p {
        padding: 0 0 0 350px;

        &.chinese {
          padding: 0;
        }
      }
      span {
        padding: 0 350px 0 0;
      }

      .top {
        height: 110px;
        overflow: hidden;
        span {
          transform: translate(0,105%);
          /* font-size: 5.5vw; */
          font-size: 80px;
          display: block;
          animation: reveal 1.5s forwards .5s;
        }
      }
      .bottom {
        height: 110px;
        overflow: hidden;
        p {
          transform: translate(0,105%);
          /* font-size: 5.5vw; */
          font-size: 80px;
          display: block;
          animation: reveal 1.5s forwards 1.5s;
        }
      }
    }

    .scroll {
      display: none;
    }
  `}
`

const Hero = () => {
  const language = useContext(LanguageContext);
  const load = useContext(LoadContext);

  // useEffect(() => {
  //   const script = document.createElement("script");

  //   script.src = "assets/js2/app.js";
  //   script.async = true;

  //   document.body.appendChild(script);
  // }, [])

  return (
    <HeroDiv id="home" name="home" >

      {/* <main>
        <div className="frame">
          <div
            id="gl"
            data-imageOriginal="assets/img/ship.jpg"
            data-imageDepth="assets/img/ship_map.png"
            data-horizontalThreshold="80"
            data-verticalThreshold="50">
          </div>
        </div> */}
      <StaticImage src="../images/hero.jpg" alt="divider" className="hero" />


      {!load.load && (
      <div className="title">
        <div className="top">
          {language.language === 'english' && (
            <span>
              Leading reagent
            </span>
          )}
          {language.language === 'mandarin' && (
            <p className="chinese">
              非洲领先的试剂供应商
            </p>
          )}
          {language.language === 'french' && (
            <span>
              Premier fournisseur 
            </span>
          )}
        </div>
        <div className="bottom">
          {language.language === 'english' && (
            <p>
              supplier to Africa
            </p>
          )}
          {language.language === 'french' && (
            <p>
              de réactifs en Afrique
            </p>
          )}
        </div>
      </div>
      )}

      {language.language === 'english' && (
        <p className="body scroll">
          SCROLL
        </p>
      )}
      {language.language === 'mandarin' && (
        <p className="body scroll">
          滚动
        </p>
      )}
      {language.language === 'french' && (
        <p className="body scroll">
          faire défiler
        </p>
      )}
      {/* </main>
      <script src="js/app.js"></script> */}
      <Logo />
    </HeroDiv>
  );
}
export default Hero;