import React from "react"
import { ScrollerMotion } from 'scroller-motion'
import { Helmet } from 'react-helmet';

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/Hero"
import About from "../components/About"
import Goal from "../components/Goal"
import Clients from "../components/Clients"
import Rect from "../components/RectImage"
import Founder from "../components/Founder"
import Contact from "../components/Contact"
import Logo from "../components/Logo"
// import { isMobile } from "react-device-detect";

import Nav from "../components/Nav"
import ProductModal from "../components/ProductModal/productModal"
import SocialImg from '../images/social.png'

const IndexPage = () => {
  // const [width, setWidth] = useState(0)

  // useEffect(() => {
  //   if(typeof window!== `undefined`) {
  //     const size = window.innerWidth
  //     setWidth(size)
  //   }
  // })

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:title" content="Petronex, the Leading reagent supplier to Africa" />
        <meta property="og:description" content="Petronex is a privately held company, headquartered in Johannesburg, South Africa. Petronex supplies a range of commodities and raw materials to a diversified customer base locally and internationally." />
        <meta property="og:image" content={SocialImg} />
      </Helmet>
      <Layout>
        <ScrollerMotion scale={1.75} className="outer-con">
          <>
            <Seo title="Petronex, the Leading reagent supplier to Africa" />
            <Logo />
            <Hero />
            <About />
            <Rect />
            <Goal />
            <Clients />
            <ProductModal />
            <Founder />
            <Contact />
          </>
        </ScrollerMotion>
        <Nav />
      </Layout>
    </>
  )
}

export default IndexPage
