import React, { useContext } from "react"
import styled from 'styled-components'
import LanguageContext from '../../context/languageContext'

import media from '../../styles/media'

const BottomContactDiv = styled.div`
  height: auto;
  background: white;
  position: relative;
  padding: var(--gutter-l) var(--gutter-s) var(--gutter-ml);
  text-align: right;
  font-size: 30px;
  line-height: 30px;
  width: calc(100vw - calc(var(--gutter-s) * 2));

  a {
    color: black;
    text-decoration: none;
  }
  .left {
    display: grid;
    margin-bottom: 30px;
    .email {
      padding-bottom:40px;
    }
  }

  p {
    margin: 0;
  }

  .rights{ 
    position: absolute;
    bottom: 3vh;
    left: var(--gutter-s);
    font-size: 12px;
    line-height: 12px;
  }

  ${media.tablet`
    padding: var(--gutter-m);
    width: 100%;
  `}
  
  ${media.laptop`
    font-size: 3.5vw;
    line-height: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 var(--gutter-m) 0;
    width: 100vw;
    grid-column-gap: var(--gutter-l);
    text-align: left;
    height: calc(var(--vh, 1vh) * 60);

    .left {
      display: flex;
      margin-bottom: 0;
      flex-direction: column;
      text-align: right;

      .email {
        padding: 0;
      }
    }


    .left, .right {
      padding-top: var(--gutter-l);
    }

    .rights {
      bottom: 4vh;
      left: 40px;
    }
  `}
`

const BottomContact = () => {
  const language = useContext(LanguageContext);

  return (
    <BottomContactDiv>
      <div className="left">
        <a href="mailto:sales@petronex.co.za" target="_blank" rel="noreferrer" className="email">
          <span>sales@petronex.co.za</span>
        </a>
        <a href="tel:+27118844914" target="_blank" rel="noreferrer">
          <span>+27 11 884 4914</span>
        </a>
      </div>
      <div className="right">
        <a className="address" href="https://www.google.com/maps/search/9th+Floor,+Office+Tower+Sandton+City,+Sandton+Johannesburg/@-26.1077013,28.0521016,18z/data=!3m1!4b1" target="_blank" rel="noreferrer">
            {language.language === 'english' && (
              <span>
                9th Floor, Office Tower<br/>
                Sandton City, Sandton<br/>
                Johannesburg
              </span>
            )}
            {language.language === '' && (
              <span>
                9th Floor, Office Tower<br/>
                Sandton City, Sandton<br/>
                Johannesburg
              </span>
            )}
            {language.language === 'mandarin' && (
              <p>
                南非约翰内斯堡市，<br/>
                Sandton区，<br/>
                Sandton Office Tower 9楼
              </p>
            )}
            {language.language === 'french' && (
              <span>
                9ème étage, Office Tower<br/>
                Sandton City, Sandton<br/>
                Johannesburg
              </span>
            )}
        </a>
      </div>
      <div className="rights">
            {language.language === 'english' && (
              <>
                ALL RIGHTS RESERVED. <span>©2021 PETRONEX</span>
              </>
            )}
            {language.language === '' && (
              <>
                ALL RIGHTS RESERVED. <span>©2021 PETRONEX</span>
              </>
            )}
            {language.language === 'mandarin' && (
              <>
                版权所有. ©2021 PETRONEX
              </>
            )}
            {language.language === 'french' && (
              <>
                ALL RIGHTS RESERVED. <span>©2021 PETRONEX</span>
              </>
            )}
          </div>
    </BottomContactDiv>
  );
}

export default BottomContact;