import React from "react"
import styled from 'styled-components'
// import { StaticImage } from "gatsby-plugin-image"

import media from '../../styles/media'
import Logo from '../../images/logo.svg'

const TopContactDiv = styled.div`
  height: 300px;
  padding: 0 var(--gutter-m);
  width: 100%;;
  background:black;
  display: flex;
  justify-content: center;
  margin-top: -1px;

  img {
    z-index: 2;
    opacity: 1;
    height: 50px;
    width: 55vw;
    margin: auto;
    object-fit: contain !important;
  }
  
  ${media.tablet`
    padding: 0;
    width: 100%;
    height: calc(var(--vh, 1vh) * 40);

    img {
      height: auto;
      width: 30vw;
      margin: auto;
      object-fit: contain;
    }
  `}
  
  ${media.laptop`
    padding: 0;
    width: 100vw;
  `}
`

const TopContact = () => {
  return (
    <TopContactDiv id="contact">
      <img src={Logo} alt="logo" />
    </TopContactDiv>
  );
}

export default TopContact;