import React, { useState, useEffect, useContext } from "react"
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import ProductContext from '../context/productContext'
import LanguageContext from '../context/languageContext'

import media from '../styles/media'
import Arrow from "../images/button.png"

const ProductListDiv = styled.div`
  /* height: 600px; */
  background: #cfcfcf;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: var(--gutter-l) var(--gutter-s);
  display: block;
  align-items: center;

  ul {
    margin-top: 40px;
    list-style: none;
    padding: 0;
    /* border-top: 1px solid black; */
    &::before {
      content: '';
      display: block;
      width: 0;
      height: 1px;
      background: black;
      transition: width .3s linear 1s;
    }
    &.active {
      &::before {
        width: 100%;
      }
    }

    li {
      .item {
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
        align-items: center;
        p {
          margin-bottom: 0;
        }
      }
      font-size: 18px;
      /* border-bottom: 1px solid black; */
      align-items: center;
      &::after {
        content: '';
        display: block;
        width: 0;
        height: 1px;
        background: black;
        transition: width .3s linear 1s;
      }
      &.active {
        &::after {
          width: 100%;
        }
      }

      button {
        cursor: pointer;
        background: none;
        border: none;
        outline: none;
        align-items: center;
          width: 25px;
          height: 25px;
          padding: 0;
        img {
          width: 25px;
          height: 25px;
          margin: 0;
          padding: 0;
          transition: all .3s linear;
          
          &.rotate {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  ${media.tablet`
    width: 100%;
    padding: var(--gutter-m);
    max-width: 100vw;
  `}

  ${media.laptop`
    display: flex;
    padding: 0 var(--gutter-l) 0 var(--gutter-left);
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);

    .products {
      max-width: 45vw;
    }

    ul {
      margin-top: 40px;
      li {
        margin: 0;
        .item {
          padding: 20px 0;
        }
        font-size: 26px;
        button {
          margin-left: 30px;
          width: 40px;
          height: 40px;

          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  `}
`

const ProductList = () => {
  const product = useContext(ProductContext);
  const [shown, setShown] = useState(false)
  const language = useContext(LanguageContext);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if(inView) {
      setShown(true)
    }
  }, [inView])

  const setIndustrial = () =>{
    product.updateProd('industrial')
    localStorage.setItem("product", 'industrial')
    if(typeof window !== `undefined`) {
      console.log(window.pageYOffset);
      document.documentElement.style.setProperty('--top', `${window.pageYOffset}px`);
    }
  }
  const setEnergy = () =>{
    product.updateProd('energy')
    localStorage.setItem("product", 'energy')
    if(typeof window !== `undefined`) {
      console.log(window.pageYOffset);
      document.documentElement.style.setProperty('--top', `${window.pageYOffset}px`);
    }
  }
  const setChemical = () =>{
    product.updateProd('chemical')
    localStorage.setItem("product", 'chemical')
    if(typeof window !== `undefined`) {
      console.log(window.pageYOffset);
      document.documentElement.style.setProperty('--top', `${window.pageYOffset}px`);
    }
  }

  return (
    <>
    <ProductListDiv id="product" name="product">
      <div className="products" ref={ref}>
        <p className={shown ? 'head shown' : 'head hidden'}>
            {language.language === 'english' && (
              <span>
                Our Products
              </span>
            )}
            {language.language === '' && (
              <span>
                Our Products
              </span>
            )}
            {language.language === 'mandarin' && (
              <>
                货品
              </>
            )}
            {language.language === 'french' && (
              <span>
                Produits
              </span>
            )}
        </p>
        <ul className={shown ? 'active' : ''}>
          <li className={shown ? 'body active' : 'body'}>
            <div className="item">
            {language.language === 'english' && (
              <p>
                Industrial Minerals and Raw Materials
              </p>
            )}
            {language.language === '' && (
              <p>
                Industrial Minerals and Raw Materials
              </p>
            )}
            {language.language === 'mandarin' && (
              <p>
                工业矿产及原材料
              </p>
            )}
            {language.language === 'french' && (
              <p>
                Minéraux industriels et matières premières
              </p>
            )}
            <button onClick={setIndustrial}>
              <img src={Arrow} alt="button" className={product.product === 'industrial' ? 'rotate' : '' } />
            </button>
            </div>
          </li>
          <li className={shown ? 'body active' : 'body'}>
            <div className="item">
            {language.language === 'english' && (
              <p>
                Energy Products
              </p>
            )}
            {language.language === '' && (
              <p>
                Energy Products
              </p>
            )}
            {language.language === 'mandarin' && (
              <p>
                能源产品
              </p>
            )}
            {language.language === 'french' && (
              <p>
                Produits énergétiques
              </p>
            )}
            <button onClick={setEnergy}>
              <img src={Arrow} alt="button" className={product.product === 'energy' ? 'rotate' : '' } />
            </button>
            </div>
          </li>
          <li className={shown ? 'body active' : 'body'}>
            <div className="item">
            {language.language === 'english' && (
              <p>
                Chemical Products
              </p>
            )}
            {language.language === '' && (
              <p>
                Chemical Products
              </p>
            )}
            {language.language === 'mandarin' && (
              <p>
                化工产品
              </p>
            )}
            {language.language === 'french' && (
              <p>
                Produits chimiques
              </p>
            )}
            <button onClick={setChemical}>
              <img src={Arrow} alt="button"  className={product.product === 'chemical' ? 'rotate' : '' }/>
            </button>
            </div>
          </li>
        </ul>
      </div>
    </ProductListDiv>
      </>
  );
}
export default ProductList;