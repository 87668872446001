import { Reveal, Tween } from "react-gsap"
import React, { useEffect, useState, useContext } from "react"
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import LanguageContext from '../context/languageContext'

import media from '../styles/media'

const IntroDiv = styled.div`
  @keyframes reveal {
    to {
      transform: translate(0,0);
    }
  }

  background: white;
  padding: var(--gutter-l) var(--gutter-s);
  width: calc(100vw - calc(var(--gutter-s) * 2));

  .body {
    padding-top: 0px;
    opacity: 1;
    transition: all .5s linear 1s;

    &.shown {
      padding-top: 0px;
      opacity: 1;
    }
  }

  ${media.tablet`

  padding: var(--gutter-m);
  .body {
    margin-top: 20px;
  }
  `}

  ${media.laptop`
    display: grid;
    height: calc(var(--vh, 1vh) * 100);
    grid-template-columns: 1fr 1fr;
    grid-column-gap: var(--gutter-m);
    padding: 0 var(--gutter-ml) 0 var(--gutter-left);
    width: 100%;
    align-items: center;

    .body {
      margin: 0;
    }
  `}
`

const Intro = () => {
  const language = useContext(LanguageContext);
  const [shown, setShown] = useState(false)

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if(inView) {
      setShown(true)
    }
  }, [inView])

  return (
    <IntroDiv id="about" name="about" >
       <div className="heading" ref={ref}>
        <div className={shown ? 'head shown' : 'head hidden'}>
          {language.language === 'english' && (
            <span>
              Steadfast
            </span>
          )}
          {language.language === undefined && (
            <span>
              Steadfast
            </span>
          )}
          {language.language === 'mandarin' && (
            <p>
              坚定地承诺
            </p>
          )}
          {language.language === 'french' && (
            <span>
              Un engagement 
            </span>
          )}
        </div>
        <div className={shown ? 'head bottom shown' : 'head bottom hidden'}>
          {language.language === undefined && (
            <p>
              commitment
            </p>
          )}
          {language.language === 'english' && (
            <p>
              commitment
            </p>
          )}
          {language.language === 'mandarin' && (
            <></>
          )}
          {language.language === 'french' && (
            <p>
              indéfectible
            </p>
          )}
        </div>
      </div>
      <Reveal>
          <Tween from={{ paddingTop: `10px`, opacity: 0 }} duration={1}>
          <div className='body'>
            {language.language === '' && (
              <>
                <p>
                  Petronex is a privately held company, headquartered in Johannesburg, South Africa and supplies a range of commodities and raw materials to a diversified customer base. Our counterparts are both producers and industrial consumers. We are a consistent and innovative partner to businesses in segments of the market which we serve and support through their development cycle.
                </p>
                <p>
                  Customers in industries such as mining and minerals processing, fuel distribution, retail and transport, rely upon our established operations as a source of input products. These commodities are supplied to Petronex from numerous first class companies.
                </p>
              </>
            )}
            {language.language === 'english' && (
              <>
                <p>
                  Petronex is a privately held company, headquartered in Johannesburg, South Africa and supplies a range of commodities and raw materials to a diversified customer base. Our counterparts are both producers and industrial consumers. We are a consistent and innovative partner to businesses in segments of the market which we serve and support through their development cycle.
                </p>
                <p>
                  Customers in industries such as mining and minerals processing, fuel distribution, retail and transport, rely upon our established operations as a source of input products. These commodities are supplied to Petronex from numerous first class companies.
                </p>
              </>
            )}
            {language.language === 'mandarin' && (
              <>
                <p>
                  Petronex是一家总部在南非，约翰内斯堡的私有公司。主要业务是为多类客户群供应各种货品及原材料。公司的同行既有生产方，也有工业消费者。
                  我们在所服务并支持其发展周期的市场领域内，是个坚定并创新的合作伙伴。
                </p>
                <p>
                  在为例采矿，矿产处理，燃料经销、售卖以及运输方面的客户，依仗我们的既定运营作为输入产品的来源。这些货品由多家一流公司提供给Petronex。
                </p>
              </>
            )}
            {language.language === 'french' && (
              <>
                <p>
                  Petronex est une société privée, dont le siège est à Johannesburg, en Afrique du Sud, qui fournit une gamme de produits de base et de matières premières à une clientèle diversifiée. Nos partenaires commerciaux sont à la fois des producteurs et des consommateurs industriels. Nous offrons aux entreprises des segments du marché que nous servons, un partenariat stable et innovant et nous les soutenons tout au long de leur cycle de développement.
                </p>
                <p>
                  Nos clients des industries telles que l'exploitation minière et le traitement des minéraux, la distribution, la vente et le transport du carburant comptent sur nos opérations et produits bien établis. Nos produits nous sont fournis par de nombreuses entreprises de premier ordre
                </p>
              </>
            )}
          </div>
        </Tween>
      </Reveal>
    </IntroDiv>
  );
}
export default Intro;