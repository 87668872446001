import React, { useContext, useEffect } from "react"
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import LanguageContext from '../../context/languageContext'
import ProductContext from "../../context/productContext"

import media from '../../styles/media'

const DarkBg = styled.div`
  width: 100vw;
  height: 100%;
  z-index: -1;
  opacity: 0;
  background: rgba(32, 32, 32, 0.9);
  transition: all .25s linear;
  position: fixed;
  left: 0;
  top: 0;

  &.visible {
    z-index: 1;
    width: 100vw;
    height: 100%;
    opacity:1;
  }

  ${media.laptop`
    background: rgba(32, 32, 32, 0.66);
  `}
`

const ProductDiv = styled.div`
  position: fixed;
  top: calc(var(--top) / 1.83);
  color: white;
  width: 100vw;
  right: -100vw;
  transition: right .5s ease-out;
  z-index: 3;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  width: calc(100vw - calc(var(--gutter-s) * 2));
  height: 650px;
  overflow: scroll;

  img{
    height: auto;
  }

  button {
    cursor: pointer;
    background: none;
    border: none;
    outline: none;

    img {
      width: 40px;
      height: 40px;
    }
  }

  .heading {
    margin-bottom: var(--gutter-s);
  }
  
  .body {
    margin-bottom: 20px;
  }

  .inner {
    background: black;
    padding: var(--gutter-s);
  }

  &.shown {
    right: 5vw;
  }

  img, .gatsby-image-wrapper, .gatsby-image-wrapper-constrained {
    width: 100%;
    height: 250px;
    object-fit: cover !important;
  }
  
  .gatsby-image-wrapper, .gatsby-image-wrapper-constrained {
    margin-bottom: 20px;
  }

  ${media.laptop`
    height: calc(var(--vh, 1vh) * 95);
    overflow: scroll;
    width: 40vw;
    right: -50vw;
    padding: 0;
    position: fixed;
    top: calc(var(--top) / 1.83);

    button {
      cursor: pointer;
      background: none;
      border: none;
      outline: none;

      img {
        width: 40px;
        height: 40px;
      }
    }

    &.shown {
      right: 5vh;
    }

    .inner {
      padding: var(--gutter-s) 30px;
      margin-bottom: 40px;
    }

    .heading {
      font-size: 26px;
      line-height: 30px;
      margin-bottom: var(--gutter-s);
    }

    .body, img {
      margin-bottom: 40px;
    }

    img, .gatsby-image-wrapper, .gatsby-image-wrapper-constrained {
      width: 100%;
      height: 350px;
      object-fit: cover !important;
    }

    .gatsby-image-wrapper, .gatsby-image-wrapper-constrained {
      margin-bottom: 40px;
    }
  `}
`

const ExitButton = styled.div`
  position: fixed;
  opacity: 0;
  transition: opacity .5s linear;
  right: 5vw;
  top: calc(var(--top) / 1.58);
  z-index: 3;

  button {
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    .desk {
      display: none;
    }

    img {
      width: 40px;
      height: 40px;
    }
  }

  &.shown {
    opacity: 1;
  }

  ${media.laptop`
    right: 45vw;
    bottom: auto;
    position: fixed;
    top: calc(var(--top) / 1.83);

    button {
      .desk {
        display: block;
      }
      .mob {
        display: none;
      }
      img {
      }
    }
  `}
`

const ProductModal = () => {
  const language = useContext(LanguageContext);
  const product = useContext(ProductContext);

  const disableScroll = () => {
    if(typeof window !== 'undefined') {
      document.documentElement.style.overflow = 'hidden';
      document.body.scroll = "no";
    }
  }

  const enableScroll = () => {
    if(typeof window !== 'undefined') {
      document.documentElement.style.overflow = 'scroll';
      document.body.scroll = "yes";
    }
  }

  useEffect(() => {
    if (product.product !== '') {
      disableScroll()
    } else {
      enableScroll()
    }
  }, [product.product])

  const clearProduct = () =>{
    product.updateProd('')
  }

  return (
    <>
      <DarkBg className={product.product !== '' ? 'visible' : '' } id="back" />

      <ProductDiv className={product.product === 'industrial' ? 'industrial shown' : 'industrial hidden' }>
        <div className="inner">
          {language.language === 'english' && (
            <p className="heading">
              Industrial Minerals and Raw Materials
            </p>
          )}
          {language.language === 'mandarin' && (
            <p className="heading">
              工业矿产及原材料
            </p>
          )}
          {language.language === 'french' && (
            <p className="heading">
              Minéraux industriels et matières premières
            </p>
          )}
          <p className="body">
            {language.language === 'english' && (
              <>
                These include commodities such as Lime, Coal, Metallurgical Coke, Sulphur and Sulphuric Acid.
              </>
            )}
            {language.language === 'mandarin' && (
              <>
                包括石灰，煤炭，冶金级煤炭，硫磺以及硫酸之类的货品。
              </>
            )}
            {language.language === 'french' && (
              <>
                Cela comprend des produits tels que la chaux, le charbon, le coke métallurgique, le soufre et l'acide sulfurique.
              </>
            )}
          </p>
          <StaticImage src="../../images/products/industrial1.jpg" alt="industrial" />
          <StaticImage src="../../images/products/industrial2.jpg" alt="industrial" />
          <StaticImage src="../../images/products/industrial3.jpg" alt="industrial" />
        </div>
      </ProductDiv>
      <ProductDiv className={product.product === 'energy' ? 'energy shown' : 'energy hidden' }>
        <div className="inner">
          {language.language === 'english' && (
            <p className="heading">
              Energy Products
            </p>
          )}
          {language.language === 'mandarin' && (
            <p className="heading">
              能源产品
            </p>
          )}
          {language.language === 'french' && (
            <p className="heading">
              Produits énergétiques
            </p>
          )}
          <p className="body">
            {language.language === 'english' && (
              <>
                Energy products that are marketed by Petronex include: Bitumen, Diluent, Gasoil, HFO, Jet A1, Kerosene, LPG, Lubricants, Mogas, Paraffin and Solvents.
              </>
            )}
            {language.language === 'mandarin' && (
              <>
                由Petronex营销的能源产品包括：沥青，稀释剂，汽油，HFO，Jet A1，煤油，液化石油气，润滑剂，Mogas燃油，石蜡和溶剂。
              </>
            )}
            {language.language === 'french' && (
              <>
                Les produits énergétiques commercialisés par Petronex comprennent : Bitume, Diluant, Gasoil, HFO, Jet A1, Kérosène, GPL, Lubrifiants, Mogas, Paraffine et Solvants.
              </>
            )}
          </p>
          <p className="body">
          </p>
          <StaticImage src="../../images/products/energy1.jpg" alt="industrial" />
          <StaticImage src="../../images/products/energy2.jpg" alt="industrial" />
          <StaticImage src="../../images/products/energy3.jpg" alt="industrial" />
        </div>
      </ProductDiv>
      <ProductDiv className={product.product === 'chemical' ? 'chemical shown' : 'chemical hidden' }>
        <div className="inner">
          {language.language === 'english' && (
            <p className="heading">
              Chemical Products
            </p>
          )}
          {language.language === 'mandarin' && (
            <p className="heading">
              化工产品
            </p>
          )}
          {language.language === 'french' && (
            <p className="heading">
              Produits chimiques
            </p>
          )}
          <p className="body">
            {language.language === 'english' && (
              <>
                Our chemical products include: Sodium Hydrosulphide, Sodium Metabisulphate, Sodium Cyanide, Sodium Sulphide, Soda Ash, Caustic Soda.
              </>
            )}
            {language.language === 'mandarin' && (
              <>
                我们的化工产品包括：硫化氢钠，焦亚硫酸钠，氰化钠，硫化钠，纯碱，烧碱。
              </>
            )}
            {language.language === 'french' && (
              <>
                Nos produits chimiques comprennent : Hydrosulfure de sodium, Métabisulfite de sodium, Cyanure de sodium, Sulfure de sodium, Carbonate de soude, Soude caustique.
              </>
            )}
          </p>
          <StaticImage src="../../images/products/chemical1.jpg" alt="industrial" />
          <StaticImage src="../../images/products/chemical2.jpg" alt="industrial" />
          <StaticImage src="../../images/products/chemical3.jpg" alt="industrial" />
        </div>
      </ProductDiv>

      <ExitButton className={product.product !== '' ? 'shown' : 'hidden' }>
        <button onClick={clearProduct}>
          <StaticImage src="../../images/exit.png" alt="button" className="desk" />
          <StaticImage src="../../images/exit-mob.png" alt="button" className="mob" />
        </button>
      </ExitButton>
      </>
  );
}
export default ProductModal;